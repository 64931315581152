@if (!isNewRoute) {
	<dk-page-container [datePicker]="false" [editButton]="false" [isLoading]="isLoading$ | async" [title]="(localisedTexts$ | async)?.title">
		<div class="dk-grid zoomed-out">
			<mat-tab-group (selectedTabChange)="onTabChanged($event.index)" [(selectedIndex)]="selectedTabIndex">
				<mat-tab [label]="(localisedTexts$ | async)?.hourlySalesTile">
					<dk-hourly-sales-tile></dk-hourly-sales-tile>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)?.weeklyReportTile">
					<dk-weekly-report-tile></dk-weekly-report-tile>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)['synthesis-report']">
					<dk-synthesis-report [visible]="selectedTabIndex === 2"></dk-synthesis-report>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)?.operatingReportTile">
					<dk-operating-report-tile class="dk-hosted"></dk-operating-report-tile>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)?.productsReportTile">
					<dk-products-report-tile class="dk-hosted"></dk-products-report-tile>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)['employee-meals']">
					<dk-employee-meals [visible]="selectedTabIndex === 5" class="dk-hosted"></dk-employee-meals>
				</mat-tab>
				<mat-tab [label]="(localisedTexts$ | async)['discount-report']">
					<dk-discounts class="dk-hosted"></dk-discounts>
				</mat-tab>
			</mat-tab-group>
		</div>
	</dk-page-container>
} @else {
	<div class="dk-grid zoomed-out">
		<mat-tab-group (selectedTabChange)="onTabChanged($event.index)" [(selectedIndex)]="selectedTabIndex">
			<mat-tab [label]="(localisedTexts$ | async)?.hourlySalesTile">
				<dk-hourly-sales-tile></dk-hourly-sales-tile>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)?.weeklyReportTile">
				<dk-weekly-report-tile></dk-weekly-report-tile>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)['synthesis-report']">
				<dk-synthesis-report [visible]="selectedTabIndex === 2"></dk-synthesis-report>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)?.operatingReportTile">
				<dk-operating-report-tile class="dk-hosted"></dk-operating-report-tile>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)?.productsReportTile">
				<dk-products-report-tile class="dk-hosted"></dk-products-report-tile>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)['employee-meals']">
				<dk-employee-meals [visible]="selectedTabIndex === 5" class="dk-hosted"></dk-employee-meals>
			</mat-tab>
			<mat-tab [label]="(localisedTexts$ | async)['discount-report']">
				<dk-discounts class="dk-hosted"></dk-discounts>
			</mat-tab>
		</mat-tab-group>
	</div>
}