import { BKDeliveryModeEnum, BKOrderState, BKOrderStateDetail, IBKKingdomRebootCoupon } from '@bk/jscommondatas';
import { UPDATE_TYPE } from '@libs/shared/models';
import { createReducer, on } from '@ngrx/store';
import { Shar3dUtils } from '@shar3d/shar3d-utils';
import { append, clone, compose, isNil, lensPath, mergeDeepRight, mergeRight, pipe, reject, set, whereEq } from 'ramda';
import { IOrderState } from '../interfaces';
import {
	AddDiscountToOrder,
	AddOrderEvents,
	AddOrderEventsType,
	AddProductToOrder,
	ChangeProductQuantityFinish,
	ChangeProductQuantityType,
	CleanOrderStateSuccess,
	CreateNewOrder,
	CreateOrderDataType,
	RequestFailure,
	SetOrderEvents,
	SetOrderEventsType,
	UpdateFidelityRewards,
	UpdateFidelityRewardsType,
	UpdateOrderEvents,
	UpdateOrderEventsType,
	UpdateOrderLocation,
	UpdateOrderLocationType,
	UpdateOrderPriceFail,
	UpdateOrderPriceResultType,
	UpdateOrderPriceSuccess,
	UpdateOrderWithFiscalPrice,
	UpdateOrderWithFiscalPriceFail,
	UpdateOrderWithFiscalPriceResultType,
	UpdateOrderWithFiscalPriceSuccess,
	UpdateOrderWithFiscalPriceType,
	UpdatePartialOrder,
	UpdatePartialOrderType,
	UpdatePaymentMethodsType,
	UpdateProductInOrderType,
	UpdateUsedPaymentMethods,
	UpsertProductInOrder,
} from './actions';

export const initialState: IOrderState = {
	data: {
		user: null,
		manager: null,
		machine: null,
		orderUUID: null,
		orderContent: [],
		orderDelContent: [],
		orderDelContentAfterDecl: [],
		orderDelContentAfterKitchenScreen: [],
		orderTotal: {
			ht: 0,
			tva: 0,
			ttc: 0,
			pc: 0,
		},
		deliveryMode: BKDeliveryModeEnum.DELIVERY_LOCAL,
		state: BKOrderState.ORDER_STATE_OPEN,
		statedetail: BKOrderStateDetail.ORDER_STATE_DETAIL_NONE,
		lineCounter: 0,
		source: null,
		creationdate: new Date().getTime(),
		paidDate: 0,
		firstProductDate: 0,
		stateChangeDate: new Date().getTime(),
		csiOrder: 0,
		csiSrc: '',
		csiDeclaredTime: 0,
		orb: 0,
		annotations: [],
		orderDiscount: [],
		event: [],
		orderGames: [],
		orderCoupons: [],
		getNextLineIndex: () => {
			return 0;
		},
		businessDay: Shar3dUtils.getBusinessday(new Date().getTime()),
	},
	usedPaymentMethods: [],
	location: {
		locationType: undefined,
		easelNum: undefined,
	},
	fiscalCorePrices: {
		isFiscalCorePriceLoading: false,
		orderDetail: undefined,
		itemsWithoutFiscalPrice: [],
		lastFiscalizedItems: [],
	},
	fidelityRewardsUsed: [],
	kioskPaymentEnabled: true,
};

const createNewOrder = (state: IOrderState, action: CreateOrderDataType) => {
	return <IOrderState>compose(set(lensPath(['data']), action.data), set(lensPath(['kioskPaymentEnabled']), true))(state);
};

const cleanOrderStateSuccess = (state: IOrderState) => {
	return <IOrderState>compose(set(lensPath([]), initialState))(state);
};

const updateLocationOrder = (state: IOrderState, action: UpdateOrderLocationType) => {
	return <IOrderState>compose(set(lensPath(['location']), { ...reject(isNil, action.location) }))(state);
};

const updatePartialOrder = (state: IOrderState, action: UpdatePartialOrderType) => {
	return <IOrderState>compose(set(lensPath(['data']), mergeRight(state.data, action.order)))(state);
};

const updateUsedPaymentMethods = (state: IOrderState, action: UpdatePaymentMethodsType) => {
	return <IOrderState>compose(set(lensPath(['usedPaymentMethods']), append(action.data, state.usedPaymentMethods)))(state);
};

const addOrderEvents = (state: IOrderState, action: AddOrderEventsType) => {
	return <IOrderState>compose(set(lensPath(['data', 'event']), [...state.data.event, ...action.data]))(state);
};

const updateOrderEvents = (state: IOrderState, action: UpdateOrderEventsType) => {
	const searchedEventIndex = state.data.event.findIndex((item) => item.eventType === action.data.eventType);
	if (searchedEventIndex >= 0) {
		return <IOrderState>compose(set(lensPath(['data', 'event', searchedEventIndex]), action.data))(state);
	} else {
		return <IOrderState>compose(set(lensPath(['data', 'event']), [...state.data.event, action.data]))(state);
	}
};

const setOrderEvents = (state: IOrderState, action: SetOrderEventsType) => {
	return <IOrderState>compose(set(lensPath(['data', 'event']), [...action.data]))(state);
};

const addProductToOrder = (state: IOrderState, action: any) => {
	return <IOrderState>compose(set(lensPath(['data', 'orderContent']), append(action.productInOrder, state.data.orderContent)))(state);
};

const addDiscountToOrder = (state: IOrderState, action: any) => {
	return <IOrderState>compose(set(lensPath(['data', 'orderDiscount']), append(action.discountInOrder, state.data.orderDiscount)))(state);
};

const changeProductQuantityFinish = (state: IOrderState, action: ChangeProductQuantityType) => {
	const rejectWhere = (arg, data) => reject(whereEq(arg), data);

	const updatedOrderContent = clone(state.data.orderContent);
	const product = updatedOrderContent.find((item) => item.lineuuid === action.lineUuid);
	product.qty = product.qty + action.quantity;

	return <IOrderState>compose(set(lensPath(['data', 'orderContent']), rejectWhere({ qty: 0 }, updatedOrderContent)))(state);
};

const updateOrderPriceSuccess = (state: IOrderState, action: UpdateOrderPriceResultType) => {
	return <IOrderState>(
		pipe(
			set(lensPath(['data', 'orderContent']), action.orderContent),
			set(lensPath(['data', 'orderTotal']), action.orderTotal),
			set(lensPath(['kioskPaymentEnabled']), true)
		)(state)
	);
};

const updateOrderPriceFail = (state: IOrderState, action: UpdateOrderPriceResultType) => {
	return <IOrderState>(
		pipe(
			set(lensPath(['data', 'orderContent']), action.orderContent),
			set(lensPath(['data', 'orderTotal']), action.orderTotal),
			set(lensPath(['kioskPaymentEnabled']), false)
		)(state)
	);
};

const updateOrderWithFiscalPrice = (state: IOrderState, action: UpdateOrderWithFiscalPriceType) => {
	return <IOrderState>(
		pipe(
			set(lensPath(['fiscalCorePrices', 'isFiscalCorePriceLoading']), true),
			set(
				lensPath(['fiscalCorePrices', 'itemsWithoutFiscalPrice']),
				append(action.itemWithoutFiscalPrice, state.fiscalCorePrices.itemsWithoutFiscalPrice)
			),
			set(
				lensPath(['fiscalCorePrices', 'lastFiscalizedItems']),
				append(action.itemWithoutFiscalPrice, state.fiscalCorePrices.itemsWithoutFiscalPrice)
			)
		)(state)
	);
};

const updateOrderWithFiscalPriceSuccess = (state: IOrderState, action: UpdateOrderWithFiscalPriceResultType) => {
	return <IOrderState>(
		pipe(
			set(lensPath(['data']), mergeRight(state.data, action.order)),
			set(lensPath(['fiscalCorePrices', 'orderDetail']), action.orderDetail),
			set(lensPath(['fiscalCorePrices', 'itemsWithoutFiscalPrice']), []),
			set(lensPath(['kioskPaymentEnabled']), true),
			set(lensPath(['fiscalCorePrices', 'isFiscalCorePriceLoading']), false)
		)(state)
	);
};

const updateOrderWithFiscalPriceFail = (state: IOrderState, action: UpdateOrderWithFiscalPriceResultType) => {
	return <IOrderState>(
		pipe(
			set(lensPath(['data']), mergeDeepRight(state.data, action.order)),
			set(lensPath(['fiscalCorePrices', 'orderDetail']), action.orderDetail ?? state.fiscalCorePrices.orderDetail),
			set(lensPath(['kioskPaymentEnabled']), false),
			set(lensPath(['fiscalCorePrices', 'isFiscalCorePriceLoading']), false)
		)(state)
	);
};

const updateProductInOrderType = (state: IOrderState, action: UpdateProductInOrderType) => {
	const updatedOrderContent = clone(state.data.orderContent);
	const index = updatedOrderContent.findIndex((item) => item.lineuuid === action.data.lineUuid);
	updatedOrderContent[index] = action.data.data;

	return <IOrderState>compose(set(lensPath(['data', 'orderContent']), updatedOrderContent))(state);
};

const requestFailure = (state: IOrderState) => {
	return <IOrderState>compose(set(lensPath(['fiscalCorePrices', 'isFiscalCorePriceLoading']), false))(state);
};

const updateFidelityRewards = (state: IOrderState, action: UpdateFidelityRewardsType) => {
	const updateFidelityRewards = (data: IBKKingdomRebootCoupon[]) => {
		if (action.updateType === UPDATE_TYPE.ADD) {
			return append(action.data, data);
		}

		return reject((item: IBKKingdomRebootCoupon) => item.idReboot === action.data.idReboot, data);
	};

	const dataToSave = updateFidelityRewards(state.fidelityRewardsUsed);
	return <IOrderState>compose(set(lensPath(['fidelityRewardsUsed']), dataToSave))(state);
};

export const reducers = createReducer(
	initialState,
	on(CreateNewOrder, createNewOrder),
	on(UpdateOrderLocation, updateLocationOrder),
	on(AddProductToOrder, addProductToOrder),
	on(AddDiscountToOrder, addDiscountToOrder),
	on(UpdatePartialOrder, updatePartialOrder),
	on(AddOrderEvents, addOrderEvents),
	on(SetOrderEvents, setOrderEvents),
	on(UpdateOrderEvents, updateOrderEvents),
	on(CleanOrderStateSuccess, cleanOrderStateSuccess),
	on(ChangeProductQuantityFinish, changeProductQuantityFinish),
	on(UpdateOrderPriceSuccess, updateOrderPriceSuccess),
	on(UpdateOrderPriceFail, updateOrderPriceFail),
	on(UpdateOrderWithFiscalPrice, updateOrderWithFiscalPrice),
	on(UpdateOrderWithFiscalPriceSuccess, updateOrderWithFiscalPriceSuccess),
	on(UpdateOrderWithFiscalPriceFail, updateOrderWithFiscalPriceFail),
	on(UpsertProductInOrder, updateProductInOrderType),
	on(RequestFailure, requestFailure),
	on(UpdateUsedPaymentMethods, updateUsedPaymentMethods),
	on(UpdateFidelityRewards, updateFidelityRewards)
);
