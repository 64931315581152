<div class="logo-app w-[184px] h-[40px]">
</div>

<div class="flex items-center content-center gap-5">
	<div class="text-accent-primary cursor-pointer" (click)="languageChange()">
		<span class="material-icons">language</span>
	</div>

	<div
		class="border border-solid border-accent-600 rounded-[100px] px-[16px] py-[10px] cursor-pointer flex gap-5 content-between items-center">
		<div class="logo w-[24px] !h-[24px]"></div>
		<p class="m-0 line-height-[20px] text-accent-700">{{ userName() }}</p>
	</div>
</div>