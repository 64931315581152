import { ChangeDetectionStrategy, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEqual, xorWith } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { REPORTS_FACADE } from '../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../facade/reports-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent implements OnInit, OnDestroy {
	public localisedTexts$ = this._translocoService.selectTranslateObject('reports');
	public isLoading$ = this._reportsServiceProvider.isLoadingSavedRestaurant$;
	selectedTabIndex = 0;

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	constructor(
		@Inject(REPORTS_FACADE) private readonly _reportsServiceProvider: ReportsServiceProvider,
		private readonly _translocoService: TranslocoService
	) {}

	ngOnDestroy(): void {
		this._reportsServiceProvider.switchMultiSeleciton(false);
	}

	ngOnInit() {
		this._reportsServiceProvider.switchMultiSeleciton(true);
		combineLatest([this._reportsServiceProvider.selectedRestaurants$, this._reportsServiceProvider.isLoadingSavedRestaurant$])
			.pipe(
				filter(([a, loading]) => {
					return !loading && a.length > 0 && !!a[0];
				}),
				distinctUntilChanged((prev, curr) => this.customComparator(prev[0], curr[0])),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this._reportsServiceProvider.fetch(this.selectedTabIndex);
			});
	}

	customComparator(prev, curr) {
		return (
			xorWith(
				prev.map((rest) => rest?.id),
				curr.map((rest) => rest?.id),
				isEqual
			).length === 0
		);
	}

	onTabChanged(index) {
		this._reportsServiceProvider.fetch(index);
	}
}
